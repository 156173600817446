import styles from './header.module.css';

type Props = {
    leftContent?: React.ReactNode;
};

export default function Header({ leftContent }: Props) {
    return (
        <div className={styles.header}>
            {leftContent != null && (
                <div className={styles.leftContent}>{leftContent}</div>
            )}
            Pollorama
        </div>
    );
}
