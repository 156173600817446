import Button, { ButtonStyle } from '../components/button';
import Header from '../components/header';
import styles from './home.module.css';

export default function PageHome() {
    return (
        <>
            <Header />
            <div className={styles.buttons}>
                <Button href="/create" style={ButtonStyle.Big}>
                    Create
                </Button>
                <Button href="/join" style={ButtonStyle.Big}>
                    Join
                </Button>
            </div>
        </>
    );
}
