export type TLogger = (...args: any[]) => void;

export enum LogSource {
    PollPage = 'PollPage',
    Api = 'Api',
}

export default function useLogger(source: LogSource): TLogger {
    return (...args: any[]) => console.log(`[${source}]`, ...args);
}
