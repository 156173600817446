import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonStyle } from '../components/button';
import Header from '../components/header';
import Icon, { IconType } from '../components/icon';
import InputText from '../components/inputText';
import { executeAsync } from '../contexts/errorHandler';
import { usePollContext } from '../contexts/pollContext';
import { Api } from '../networkManager';
import styles from './create.module.css';

export default function PageCreate() {
    const [question, setQuestion] = useState<string>('');
    const [creating, setCreating] = useState<boolean>(false);
    const { setPollData } = usePollContext();
    const navigate = useNavigate();
    return (
        <>
            <Header leftContent={<Icon type={IconType.Back} href="/" />} />
            <form
                className={styles.root}
                onSubmit={(e) => {
                    executeAsync(async () => {
                        setCreating(true);
                        const pollData = await Api.createPoll({ question });
                        setPollData(pollData);
                        navigate(`/poll/${pollData.poll.code}`);
                    });
                    e.preventDefault();
                    return false;
                }}
            >
                <div className={styles.label}>Question</div>
                <InputText
                    value={question}
                    onChange={setQuestion}
                    placeholder="Which movie should we watch?"
                    disabled={creating}
                    autoFocus
                />
                <Button
                    type="submit"
                    style={ButtonStyle.Small}
                    disabled={creating}
                    spinner={creating}
                >
                    Create Poll
                </Button>
            </form>
        </>
    );
}
