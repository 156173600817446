import React, { useContext, useState } from 'react';
import { ChildrenProps } from '../types';
import useMyEffect from '../util/useMyEffect';

type TErrorContext = {
    error: Error | null;
    setError: (error: Error | null) => void;
};

const ErrorContext = React.createContext<TErrorContext>(null as any);

const staticErrorContext: { ctx: TErrorContext | null } = { ctx: null };

export function useErrorContext(): TErrorContext {
    return useContext(ErrorContext);
}

export function executeAsync(func: () => Promise<any>) {
    func().catch((e) => staticErrorContext.ctx?.setError(e));
}

export default function ErrorHandler({ children }: ChildrenProps) {
    const [error, setError] = useState<Error | null>(null);
    useMyEffect(() => {
        staticErrorContext.ctx = { error, setError };
    }, []);
    return (
        <ErrorContext.Provider value={{ error, setError }}>
            {error === null ? <>{children}</> : <div>{error.message}</div>}
        </ErrorContext.Provider>
    );
}
