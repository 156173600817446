import {
    ClientToServerEvents,
    CreatePollRequest,
    FrontendPollData,
    PollPublic,
    PollVoterData,
    ServerToClientEvents,
} from 'common';
import { useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { ChildrenProps } from './types';
import useLogger, { LogSource, TLogger } from './util/useLogger';
import useMyEffect from './util/useMyEffect';

type MySocket = Socket<ServerToClientEvents, ClientToServerEvents>;

export function NetworkManager({ children }: ChildrenProps) {
    const [initialized, setInitialized] = useState<boolean>(false);
    const log = useLogger(LogSource.Api);
    useMyEffect(() => {
        Api.init(log).then(() => setInitialized(true));
    }, []);
    if (!initialized) {
        return <div>Loading...</div>;
    }
    return <>{children}</>;
}

export class Api {
    public static socket: MySocket | null = null;
    private static socketPromises: Array<(socket: MySocket) => void> = [];

    private static log: TLogger;
    private static id: string | null = null;

    private static getSecret(): string {
        let secret = window.localStorage.getItem('secret');
        if (secret == null) {
            secret = this.createSecret();
            window.localStorage.setItem('secret', secret);
        }
        return secret;
    }

    private static createSecret(): string {
        let alpha = 'abcdefghijklmnopqrstuvwxyz1234567890';
        let secret = '';
        for (let i = 0; i < 64; i++) {
            secret += alpha.charAt(Math.floor(Math.random() * alpha.length));
        }
        return secret;
    }

    private static getSocket(): Promise<MySocket> {
        return new Promise((resolve) => {
            if (this.socket !== null) {
                resolve(this.socket);
            } else {
                this.socketPromises.push(resolve);
            }
        });
    }

    static init(log: TLogger): Promise<void> {
        this.log = log;
        return new Promise((resolve) => {
            let url = '/';
            if (window.location.host === 'localhost:3000') {
                url = 'http://localhost:8080';
            }
            console.log(`Connecting to ${url}`);
            const socket: MySocket = io(url);
            this.setupLogging(socket);
            socket.on('connect', () => {
                const secret = this.getSecret();
                console.log('connected, logging in as', secret);
                socket.emit('login', secret, (id) => {
                    this.id = id;
                    console.log('logged in, id:', id);
                    this.socket = socket;
                    while (true) {
                        const promiseResolve = this.socketPromises.pop();
                        if (promiseResolve == null) {
                            break;
                        }
                        promiseResolve(this.socket);
                    }
                    resolve();
                });
            });
            socket.on('disconnect', () => {
                console.log('disconnected');
                this.socket = null as any;
            });
        });
    }

    private static setupLogging(socket: MySocket) {
        if (!window.location.host.includes('localhost')) return;
        const emit = socket.emit.bind(socket);
        const newEmit: any = (...args: any[]) => {
            const cb = args[args.length - 1];
            const remaining = args.slice(1, args.length - 1);
            const id = Math.floor(Math.random() * 1000);
            this.log(`(${id}) [${args[0]}]`, ...remaining);
            emit(args[0], ...remaining, (...cbArgs: any[]) => {
                this.log(`(${id}) [${args[0]}] Response`, ...cbArgs);
                cb(...cbArgs);
            });
        };
        socket.emit = newEmit;
    }

    static async createPoll(
        request: CreatePollRequest
    ): Promise<FrontendPollData> {
        const socket = await this.getSocket();
        return await new Promise((resolve) =>
            socket.emit('createPoll', request, resolve)
        );
    }

    static async joinPoll(code: string): Promise<FrontendPollData | null> {
        const socket = await this.getSocket();
        return await new Promise((resolve) =>
            socket.emit('joinPoll', code, resolve)
        );
    }

    static async addOption(name: string): Promise<PollPublic> {
        const socket = await this.getSocket();
        return await new Promise((resolve) =>
            socket.emit('addOption', name, resolve)
        );
    }

    static async updateVoterData(
        voterData: PollVoterData
    ): Promise<PollPublic> {
        const socket = await this.getSocket();
        return await new Promise((resolve) =>
            socket.emit('updateVoterData', voterData, resolve)
        );
    }

    static async endPollNow(): Promise<PollPublic> {
        const socket = await this.getSocket();
        return await new Promise((resolve) =>
            socket.emit('endPollNow', resolve)
        );
    }
}
