import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import IconBack from '../res/back';
import IconCancel from '../res/cancel';
import IconCheckmark from '../res/checkmark';
import IconCircle from '../res/circle';
import IconPlus from '../res/plus';
import IconPlusFilled from '../res/plusFilled';
import styles from './icon.module.css';

export enum IconType {
    Back,
    Checkmark,
    Circle,
    Plus,
    PlusFilled,
    Cancel,
}

const srcMap: Record<IconType, React.ComponentType> = {
    [IconType.Back]: IconBack,
    [IconType.Checkmark]: IconCheckmark,
    [IconType.Circle]: IconCircle,
    [IconType.Plus]: IconPlus,
    [IconType.PlusFilled]: IconPlusFilled,
    [IconType.Cancel]: IconCancel,
};

type Props = {
    type: IconType;
    href?: string;
    size?: 20;
    onClick?: () => void;
};

export default function Icon({ type, href, size, onClick }: Props) {
    const navigate = useNavigate();
    const Component = srcMap[type];
    return (
        <div
            className={classNames(
                styles.root,
                size === 20 ? styles.s20 : styles.s30,
                {
                    [styles.clickable]: href != null || onClick != null,
                }
            )}
            onClick={() => {
                onClick?.();
                if (href != null) {
                    navigate(href);
                }
            }}
        >
            <Component />
        </div>
    );
}
