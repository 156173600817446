import { useNavigate } from 'react-router-dom';
import { ChildrenProps } from '../types';
import Util from '../util/util';
import styles from './button.module.css';
import Spinner from './spinner';

export enum ButtonStyle {
    Big,
    Small,
}

const styleMap: Record<ButtonStyle, string> = {
    [ButtonStyle.Big]: styles.big,
    [ButtonStyle.Small]: styles.small,
};

type Props = ChildrenProps & {
    type?: 'submit';
    style: ButtonStyle;
    className?: string;
    onClick?: () => void;
    href?: string;
    spinner?: boolean;
    disabled?: boolean;
};

export default function Button({
    style,
    children,
    className,
    onClick,
    href,
    spinner,
    ...props
}: Props) {
    const navigate = useNavigate();
    return (
        <button
            className={Util.classes(styles.root, className, styleMap[style])}
            onClick={() => {
                onClick?.();
                if (href != null) {
                    navigate(href);
                }
            }}
            {...props}
        >
            {children}
            {spinner && <Spinner />}
        </button>
    );
}
