import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorHandler from './contexts/errorHandler';
import { PollContextProvider } from './contexts/pollContext';
import './globals.css';
import { NetworkManager } from './networkManager';
import PageCreate from './pages/create';
import PageHome from './pages/home';
import PageJoin from './pages/join';
import PagePoll from './pages/poll';
import PageRoot from './pages/root';

const router = createBrowserRouter([
    {
        path: '/',
        element: <PageRoot />,
        children: [
            {
                index: true,
                element: <PageHome />,
            },
            {
                path: 'create',
                element: <PageCreate />,
            },
            {
                path: 'join',
                element: <PageJoin />,
            },
            {
                path: 'poll/:code',
                element: <PagePoll />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ErrorHandler>
            <PollContextProvider>
                <NetworkManager>
                    <RouterProvider router={router} />
                </NetworkManager>
            </PollContextProvider>
        </ErrorHandler>
    </React.StrictMode>
);
