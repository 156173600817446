import { Outlet } from 'react-router-dom';
import styles from './root.module.css';

export default function PageRoot() {
    return (
        <div className={styles.root}>
            <div className={styles.inner}>
                <Outlet />
            </div>
        </div>
    );
}
