import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export default function useMyEffect(
    effect: EffectCallback,
    deps?: DependencyList
) {
    const initialized = useRef<boolean>(false);
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            effect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
