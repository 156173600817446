import { DependencyList } from 'react';
import { executeAsync } from '../contexts/errorHandler';
import useMyEffect from './useMyEffect';

export default function useAsyncEffect(
    effect: () => Promise<any>,
    deps?: DependencyList
) {
    useMyEffect(() => executeAsync(async () => await effect()), deps);
}
