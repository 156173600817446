import classNames from 'classnames';
import styles from './inputText.module.css';

type Props = {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    error?: { message: string } | null;
    autoFocus?: boolean;
};

export default function InputText({
    onChange,
    className,
    error,
    ...props
}: Props) {
    return (
        <>
            <input
                className={classNames(styles.root, className, {
                    [styles.error]: error != null,
                })}
                type="text"
                onChange={(e) => onChange(e.target.value)}
                {...props}
            />
            {error != null && (
                <div className={styles.errorMessage}>{error.message}</div>
            )}
        </>
    );
}
