import classNames from 'classnames';
import {
    FrontendPollData,
    PollComplete,
    PollVoterData,
    ServerToClientEvents,
} from 'common';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button, { ButtonStyle } from '../components/button';
import Header from '../components/header';
import Icon, { IconType } from '../components/icon';
import Spinner from '../components/spinner';
import { executeAsync } from '../contexts/errorHandler';
import { usePollContext } from '../contexts/pollContext';
import { Api } from '../networkManager';
import { ChildrenProps } from '../types';
import useAsyncEffect from '../util/useAsyncEffect';
import useLogger, { LogSource } from '../util/useLogger';
import styles from './poll.module.scss';

type PollState = 'loading' | 'not_found' | 'success';

export default function PagePoll() {
    const log = useLogger(LogSource.PollPage);
    const { code } = useParams<'code'>();
    const { pollData, setPollData } = usePollContext();
    const [state, setState] = useState<PollState>('loading');
    useAsyncEffect(async () => {
        if (code == null) {
            log(`Null code param, returning`);
            return;
        }
        if (pollData != null && pollData.poll.code === code) {
            log(`Poll data matches code ${code}, returning`);
            setState('success');
            return;
        }
        log(
            `Poll data (${
                pollData?.poll.code ?? 'null'
            }) does not match code ${code}, joining poll`
        );
        const response = await Api.joinPoll(code);
        log(`Join poll response`, response);
        if (response === null) {
            setState('not_found');
        } else {
            setPollData(response);
            setState('success');
        }
    }, [code]);
    let content = null;
    if (state === 'loading') {
        content = <div>Loading....</div>;
    } else if (state === 'not_found') {
        content = (
            <div>
                <div>Poll not found for code {code}</div>
                <div>
                    <Link to="/">Home</Link>
                </div>
            </div>
        );
    } else if (pollData != null) {
        content = <Poll pollData={pollData} />;
    }
    return (
        <>
            <Header />
            {content}
        </>
    );
}

type PollProps = {
    pollData: FrontendPollData;
};

function Poll({ pollData }: PollProps) {
    const { poll, voterData } = pollData;
    const { updatePollData } = usePollContext();
    const [togglingVotingState, setTogglingVotingState] =
        useState<boolean>(false);
    useEffect(() => {
        const updatePoll: ServerToClientEvents['updatePoll'] = (poll) => {
            console.log('GOT POLL UPDATE', poll);
            updatePollData({ poll });
        };
        if (Api.socket === null) return;
        Api.socket.on('updatePoll', updatePoll);
        return () => {
            Api.socket?.off('updatePoll', updatePoll);
        };
    }, [updatePollData]);
    const updateVoterData = async (newVoterData: PollVoterData) => {
        updatePollData({
            voterData: newVoterData,
        });
        const poll = await Api.updateVoterData(newVoterData);
        updatePollData({ poll });
    };
    if (poll.type === 'complete') {
        return <PollResults poll={poll} />;
    }
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.headerText}>
                    {poll.numStillVoting} / {poll.numVoters} participants are
                    still voting
                </div>
                <div className={styles.spacer} />
                <div className={styles.code}>{poll.code}</div>
            </div>
            <div className={styles.question}>{poll.question}</div>
            <div
                className={classNames(styles.options, {
                    [styles.disabled]: pollData.voterData.doneVoting,
                })}
            >
                {Object.entries(poll.options).map(([id, option]) => (
                    <Option
                        key={id}
                        selected={id in voterData.votes}
                        onClick={() => {
                            const newVotes: PollVoterData['votes'] = {
                                ...voterData.votes,
                            };
                            if (id in newVotes) {
                                delete newVotes[id];
                            } else {
                                newVotes[id] = 1;
                            }
                            executeAsync(
                                async () =>
                                    await updateVoterData({
                                        ...voterData,
                                        votes: newVotes,
                                    })
                            );
                        }}
                    >
                        {option.name}
                    </Option>
                ))}
                <AddOption />
            </div>
            <div className={styles.bottomActions}>
                <Button
                    style={ButtonStyle.Small}
                    onClick={() =>
                        executeAsync(async () => {
                            setTogglingVotingState(true);
                            await updateVoterData({
                                ...voterData,
                                doneVoting: !voterData.doneVoting,
                            });
                            setTogglingVotingState(false);
                        })
                    }
                    disabled={togglingVotingState}
                >
                    {voterData.doneVoting
                        ? `Just kidding I'm still voting`
                        : `I'm done voting`}
                </Button>
                {voterData.isCreator && (
                    <Button
                        style={ButtonStyle.Small}
                        onClick={() =>
                            executeAsync(async () => {
                                const poll = await Api.endPollNow();
                                updatePollData({ poll });
                            })
                        }
                    >
                        End poll now
                    </Button>
                )}
            </div>
        </div>
    );
}

function AddOption() {
    const [adding, setAdding] = useState<boolean>(false);
    const [addingOption, setAddingOption] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const { pollData, updatePollData } = usePollContext();
    const disabled = pollData?.voterData.doneVoting ?? false;
    useEffect(() => {
        if (disabled) {
            setAdding(false);
            setName('');
        }
    }, [disabled]);
    const addOption = () =>
        executeAsync(async () => {
            setAddingOption(true);
            const poll = await Api.addOption(name);
            updatePollData({ poll });
            setAddingOption(false);
            setName('');
            setAdding(false);
        });
    if (adding) {
        return (
            <Option selected={false}>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            addOption();
                        }
                    }}
                    className={styles.addOptionInput}
                    disabled={addingOption}
                    autoFocus
                />
                {addingOption ? (
                    <Spinner />
                ) : (
                    <div className={styles.addOptionIcons}>
                        <Icon
                            type={IconType.Cancel}
                            size={20}
                            onClick={() => {
                                setName('');
                                setAdding(false);
                            }}
                        />
                        <Icon
                            type={IconType.PlusFilled}
                            size={20}
                            onClick={addOption}
                        />
                    </div>
                )}
            </Option>
        );
    }
    return (
        <div
            className={classNames(styles.option, styles.addOption, {
                [styles.optionClickable]: !disabled,
            })}
            onClick={() => {
                if (disabled) return;
                setAdding(true);
            }}
        >
            Add option
            <Icon type={IconType.Plus} size={20} />
        </div>
    );
}

type OptionProps = ChildrenProps & {
    selected: boolean;
    onClick?: () => void;
};

function Option({ children, selected, onClick }: OptionProps) {
    const { pollData } = usePollContext();
    const disabled = pollData?.voterData.doneVoting ?? false;
    return (
        <div
            className={classNames(styles.option, {
                [styles.selected]: selected,
                [styles.optionClickable]: onClick != null,
                [styles.disabled]: disabled,
            })}
            onClick={() => {
                if (disabled) return;
                onClick?.();
            }}
        >
            <Icon
                type={selected ? IconType.Checkmark : IconType.Circle}
                size={20}
            />
            <div className={styles.optionContent}>{children}</div>
        </div>
    );
}

function PollResults({ poll }: { poll: PollComplete }) {
    return (
        <div>
            <div>Results:</div>
            {Object.entries(poll.options).map(([id, option]) => (
                <div key={id}>
                    {option.name}: {option.numVotes}
                </div>
            ))}
        </div>
    );
}
