import { FrontendPollData } from 'common';
import React, { useCallback, useContext, useState } from 'react';
import { ChildrenProps } from '../types';
import nullthrows from '../util/nullthrows';

type TPollContext = {
    pollData: FrontendPollData | null;
    setPollData: (data: FrontendPollData) => void;
    updatePollData: (update: Partial<FrontendPollData>) => void;
};

const PollContext = React.createContext<TPollContext>(null as any);

export function usePollContext(): TPollContext {
    return useContext(PollContext);
}

export function PollContextProvider({ children }: ChildrenProps) {
    const [pollData, setPollData] = useState<FrontendPollData | null>(null);
    const updatePollData = useCallback(
        (update: Partial<FrontendPollData>) =>
            setPollData((pollData) => ({
                ...nullthrows(pollData),
                ...update,
            })),
        []
    );
    return (
        <PollContext.Provider value={{ pollData, setPollData, updatePollData }}>
            {children}
        </PollContext.Provider>
    );
}
