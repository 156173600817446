import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonStyle } from '../components/button';
import Header from '../components/header';
import Icon, { IconType } from '../components/icon';
import InputText from '../components/inputText';
import { executeAsync } from '../contexts/errorHandler';
import { usePollContext } from '../contexts/pollContext';
import { Api } from '../networkManager';
import styles from './join.module.css';

export default function PageJoin() {
    const [code, setCode] = useState<string>('');
    const [joining, setJoining] = useState<boolean>(false);
    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const navigate = useNavigate();
    const { setPollData } = usePollContext();
    return (
        <>
            <Header leftContent={<Icon type={IconType.Back} href="/" />} />
            <form
                className={styles.root}
                onSubmit={(e) => {
                    executeAsync(async () => {
                        setJoining(true);
                        const result = await Api.joinPoll(code);
                        if (result !== null) {
                            setPollData(result);
                            navigate(`/poll/${code}`);
                            return;
                        }
                        setInvalidCode(true);
                        setJoining(false);
                    });
                    e.preventDefault();
                    return false;
                }}
            >
                <div>Code</div>
                <InputText
                    value={code}
                    onChange={(code) => {
                        code = code.replace(/\D/g, '');
                        setCode(code);
                        setInvalidCode(false);
                    }}
                    className={styles.input}
                    disabled={joining}
                    error={invalidCode ? { message: 'Invalid code' } : null}
                    autoFocus
                />
                <Button
                    type="submit"
                    style={ButtonStyle.Small}
                    disabled={joining}
                    spinner={joining}
                >
                    Join Poll
                </Button>
            </form>
        </>
    );
}
